/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, Loader, Divider, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import MenuView from '../../components/MenuView';
import TopCover from '../../components/TopCover';
import Modal from '../../components/Modal';

import EntityHeader from '../Entity/Header';
import Container from '../../components/Container';

import { hasConversationProductFeature } from '../../helpers/hasConversationProductFeature';

import '../../assets/css/MainLayout.module.scss';

import { __ } from '../../i18n';


@inject('store')
@graphql(gql`query EntityNodeQuery ($id: ID!) {
    node(id: $id) @connection(key: "Entity", filter: ["id"]) {
      ... on Entity {
        id: dbId
        eid
        type
        disabled
        invisible
        isChannel
        hasViewer
        scope
        policy
        confAttendanceEnabled
        hasUnreadDirectMessages
        organization {
          id: dbId
          color
          live
          link
          type
          username
          fullname
          confArrived
          confMessage
          confMessageStudent
          confMessageStaff
          confMedia
          confCommitment
          confSurvey
          confCharge
          confReport
          confForm
          confReplyStudent
          confRepliesStudent
          confMediaStudent
          confTerms
          confPresence
          confAdminList
          network {
            id
          }
          confChannelMetrics
          confRecurrence
          created
          plan
          premiumPeriod
          isDemonstration
          monthlyMessagesCount
          monthlyMessageQuota
          confChannelMetrics
          usage
          timezone
          unleashStatus {
            approve_message
            approve_reply
            web_menu_conversations
            show_banner_menu_conversations
            network_communication
            show_banner_network_communication
          }
          organizationBanners {
            nodes {
              id
              bannerType
            }
          }
          links{
            totalCount
            nodes{
              key
              id: dbId
              icon
              title
              url
              type
              replaces
            }
          }
          productFeatures {
            totalCount
            nodes {
              id
              identifier
              plan
              paid
              live
              # isEnabled(organizationUsername: $username)
            }
          }
          features {
            messages
            messageManager
            readingControl
            files
            surveys
            commitments
            moments
            reports
            videos
            audios
            charges
            forms
            otherFiles
            dashboard
            api
            support
            labels
            deleteMessages
            editMessages
            links
            videoConference
          }
          featureToggles {
            web_menu_conversations_notification_bell
          }
          tags {
            nodes {
              id: dbId
              name
            }
          }
          permissions {
            entityScopes
            hasAdmin
          }
          paymentMethods {
            name
          }
          ...TopCover
        }
        messages (folder: UNREAD) {
          totalCount
        }
        moments (folder: NEW) {
          totalCount
        }
        ...EntityHeader
      }
    }
  }
${EntityHeader.fragments.entity}
${TopCover.fragments.organization}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.entity_id
    }
  })
})
@observer
export default class EntityNode extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { store } = this.props;
    const { data } = nextProps;
    const { unleash } = store;

    if ((data.loading && !data.node) || !data.node) return;

    const { hasViewer, organization } = data.node;
    store.entity = data.node;
    store.currentOrganization = organization;
    if (unleash && store.currentOrganization) unleash.setContext({ organizationId: [store.currentOrganization.id] });

    if (hasViewer) {
      store.currentEntity = data.node;
    } else if (!store.currentEntity || store.currentEntity.type !== 'ADMIN' || !store.currentEntity.organization || store.currentEntity.organization.id !== organization.id) {
      let myEntity = store.currentUser.entities && store.currentUser.entities
        .find(e => e.type === 'ADMIN' && (e.organization.id == organization.id));
      if (myEntity) {
        store.currentEntity = myEntity;
      } else {
        myEntity = store.currentUser.entities && store.currentUser.entities
          .find(e => e.type === 'ADMIN');
        store.currentEntity = myEntity;
      }
    }
  }

  componentWillUnmount() {
    // this.props.store.currentEntity = null;
    this.props.store.currentOrganization = null;
    this.props.store.entity = null;
  }

  render() {
    const { data, store, router } = this.props;
    const { currentEntity, unleash, currentOrganization } = store;

    if (data.error) {
      return (<Modal
        header={__('Error')}
        content={__('An error has occurred.')}
        actions={[<Button
          data-action="cancel"
          key={1}
          primary
          content={__('OK')}
          onClick={() => {
            this.setState({ onLeave: true }, () => {
              this.props.router.push('/');
            });
          }}
        />]}
      />);
    } else if ((data.loading && !data.node) || !data.node || !store.currentEntity) { return <Loader active />; }

    const { type, invisible, id, organization, messages, moments, isChannel } = data.node;

    const hasMenuConversations = (organization?.unleashStatus?.web_menu_conversations || unleash.unleash.toggles.find(toggle => toggle.name === 'web_menu_conversations')
      && hasConversationProductFeature(store.currentOrganization));

    const items = [];

    const mobileItems = [];

    if (type !== 'STUDENT' && !invisible) {
      items.push({ icon: 'tachometer alt', name: __('Dashboard'), to: `/entities/${id}/dashboard` });
    }

    if (!(store.currentUser && ((store.currentUser.entities && store.currentUser.entities.filter(e => e.type === 'ADMIN' && e.organization && organization &&
      (e.organization.id === organization.id)).length) || store.currentUser.isMaster))) {
      items.push({ icon: 'pencil', name: __('Edit'), to: `/entities/${id}/edit/picture` });
    }

    if (store.currentUser && ((store.currentUser.entities && store.currentUser.entities.filter(e => (e.type === 'ADMIN' && e.disabled === false) && e.organization && organization &&
      (e.organization.id === organization.id)).length) || store.currentUser.isMaster)) {
      items.push({ icon: 'browser', name: __('Accounts'), to: `/entities/${id}/accounts`, id: 'EntitysAccount' });
      mobileItems.push({ icon: 'browser', name: __('Accounts'), quantity: messages.totalCount, to: `/entities/${id}/accounts` });
      if (organization) items.push({ icon: 'pencil', name: __('Edit'), to: isChannel ? `/organizations/${organization.id}/channels/${id}/edit` : `/entities/${id}/edit`, id: 'EditEntity' });
    }

    mobileItems.push({ icon: 'envelope', name: __('Communications'), quantity: messages.totalCount, to: `/entities/${id}/messages`, default: `/entities/${id}` });
    items.push({ icon: 'envelope', name: __('Communications'), quantity: messages.totalCount, to: `/entities/${id}/messages` });

    if (((type === 'STAFF' && isChannel) || type === 'ADMIN') && hasMenuConversations && hasConversationProductFeature(organization)) {
      mobileItems.push({ icon: 'message', name: __('Chats'), quantity: store.currentEntity.hasUnreadDirectMessages, to: `/entities/${id}/chats` });
      items.push({ icon: 'message', name: __('Chats'), quantity: store.currentEntity.hasUnreadDirectMessages, to: `/entities/${id}/chats` });
    }

    if (organization && organization.features.moments) {
      items.push({ icon: 'heart', name: __('Moments'), quantity: moments.totalCount, to: `/entities/${id}/moments` });
      mobileItems.push({ icon: 'heart', name: __('Moments'), quantity: moments.totalCount, to: `/entities/${id}/moments` });
    }

    mobileItems.push({ icon: 'image', name: __('Media'), quantity: messages.totalCount, to: `/entities/${id}/medias` });
    items.push({ icon: 'image', name: __('Media'), to: `/entities/${id}/medias` });

    if ((organization && organization.features.commitments) || (store.currentUser && !store.currentUser.organization)) {
      items.push({ icon: 'calendar', name: __('Calendar'), to: `/entities/${id}/commitments` });
      mobileItems.push({ icon: 'calendar', name: __('Calendar'), to: `/entities/${id}/commitments` });
    }

    // using && eid !== null stage 2.
    if (type == 'STUDENT' && organization.features.api && organization.links.totalCount > 0) {
      items.push({ icon: 'box open full', name: __('Accesses'), to: `/entities/${id}/accesses` });
      mobileItems.push({ icon: 'box open full', name: __('Accesses'), to: `/entities/${id}/accesses` });
    }

    const canSendMessage = currentEntity && currentEntity.organization && currentEntity.organization.confMessage &&
      (currentEntity.organization.confMessageStaff || currentEntity.type !== 'STAFF') &&
      (currentEntity.organization.confMessageStudent || currentEntity.type !== 'STUDENT') &&
      (currentEntity.organization.features.messages) &&
      (!currentEntity.disabled);

    return (
      <Container desktopItems={items} mobileItems={mobileItems} router={router} id="EntityNodeMenu" headerButton={((store.currentEntity && store.currentEntity.id) === (store.entity && store.entity.id)) && canSendMessage ? 'write' : 'profile'}>
        {this.props.children}
      </Container>
    );
  }
}